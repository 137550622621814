import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 40,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    maxWidth: 1440,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
  },
  item: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "flex-start",
    // alignItems: "center",
    backgroundColor: "#f9f9f9",
    border: "1px solid #e7e4e4",
    boxShadow: "2px 2px 2px rgba(240, 240, 240, 1)",
    padding: 30,
    // position: "relative"
  },
  iconWrapper: {
    height: 110,
    marginBottom: 10,
    textAlign: "center"
  },
  icon: {
    width: 100,
    height: 100,
  },
  title: {
    //color: "#2b67b1",
    fontSize: "1.25rem",
    lineHeight: 1.2,
    textAlign: "center",
    marginBottom: 15,
  },
  description: {
    color: "#4d4d4d",
    lineHeight: 1.25,
    textAlign: "left",
    fontSize: "1rem",
    height: "100%"
  },
  button: {
    color: "#fff",
    fontSize: "1rem",
    //marginTop: 20,
    // minHeight: "30px",
    // lineHeight: "30px"
  },
  buttonWrapper: {
    width: "100%",
    marginTop: 10,
    textAlign: "center"
    // display: "flex",
    // justifyContent: "center",
  },
}))
