import * as React from "react"
import { navigate } from "@reach/router"
import { Grid, Typography, Button } from "@material-ui/core"

import LearnMoreButton from "../../../components/LearnMoreButton"
import { implementationList } from "./data"
import { useStyles } from "./styles"

const Implementations = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Grid container spacing={10}>
          {implementationList.map((item, index) => (
            <Grid
              item
              key={`implementation-item-${index}`}
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
            >
              <div className={classes.item}>
                <div className={classes.iconWrapper}>
                  <img src={item.icon} className={classes.icon} />
                </div>
                <Typography variant="subtitle2" className={classes.title}>
                  {item.title}
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  {item.description}
                </Typography>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => navigate(`/services/${item.link}`)}
                  >
                    Learn more
                  </Button>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        {/* <div className={classes.buttonWrapper}>
          <LearnMoreButton to="about-us" />
        </div> */}
      </div>
    </div>
  )
}

export default Implementations
