import strategicAdvisoryIcon from "../../../../media/home/strategic-advisory-icon.png"
import dataManagementIcon from "../../../../media/home/data-management-icon.png"
import technologySelectionIcon from "../../../../media/home/tech-selection-icon.png"
import systemsIntegrationIcon from "../../../../media/home/syst-integration-icon.png"

export const implementationList = [
  {
    icon: strategicAdvisoryIcon,
    title: "Strategic Advisory",
    link: "strategic-advisory",
    description: "We evaluate, recommend, and design target state operating models across people, process, and technology specific to our clients' business needs."
  },
  {
    icon: technologySelectionIcon,
    title: "Technology Implementation",
    link: "technology-implementation",
    description: "We deploy best-in-class investment management, accounting, performance, and risk management systems that support the full investment lifecycle."
  },
  {
    icon: systemsIntegrationIcon,
    title: "Systems Integration",
    link: "systems-integration",
    description: "We integrate fragmented technologies and disparate data sources to improve process automation, reduce manual data entry, and deliver accurate and timely data."
  },
  {
    icon: dataManagementIcon,
    title: "Data and Analytics",
    link: "data-and-analytics",
    description: "We design and implement processes to capture, produce, and analyze data sets that produce actionable insights based on a single source of truth."
  }
]
